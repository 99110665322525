tinymce.PluginManager.add('EMIOTemplatesPlugin', function (editor, url) {
  /**
   * Plugin Body Goes here
   * We need to register the MenuButton, the MenuItems that the MenuButton will display
   * and the callback function when a MenuItem is clicked
   */
  editor.ui.registry.addMenuButton('EMIOTemplatesPlugin', {
    text: 'Templates de contrato',
    fetch: function (callback) {
      var items = [
        {
          type: 'nestedmenuitem',
          text: 'Utilidades',
          icon: 'user',
          getSubmenuItems: function () {
            return [
              {
                type: 'menuitem',
                text: 'Fecha del día',
                icon: 'insert-time',
                onAction: function () {
                  editor.insertContent(
                    createFragment(
                      'utils.timestamp'
                    ) /*  + ' ' + createFragment('client.lastname') */
                  );
                },
              } /* ,
              {
                type: 'menuitem',
                text: 'Dirección',
                onAction: function () {
                  editor.insertContent(createFragment('client.address'));
                },
              },
              {
                type: 'menuitem',
                text: 'Email',
                onAction: function () {
                  editor.insertContent(createFragment('client.email'));
                },
              },
              {
                type: 'menuitem',
                text: 'Cédula de identidad',
                onAction: function () {
                  editor.insertContent(createFragment('client.rut'));
                },
              },
              {
                type: 'menuitem',
                text: 'Estado civil',
                onAction: function () {
                  editor.insertContent(createFragment('{civilStatus client.civilStatus}'));
                },
              },
              {
                type: 'menuitem',
                text: 'Nacionalidad',
                onAction: function () {
                  editor.insertContent(createFragment('{nationality client.nationality}'));
                },
              },
              {
                type: 'menuitem',
                text: 'Profesión',
                onAction: function () {
                  editor.insertContent(createFragment('client.profession'));
                },
              }, */,
            ];
          },
        },
        {
          type: 'nestedmenuitem',
          text: 'Cliente',
          icon: 'user',
          getSubmenuItems: function () {
            return [
              {
                type: 'menuitem',
                text: 'Nombre completo',
                onAction: function () {
                  editor.insertContent(
                    createFragment('client.name') /*  + ' ' + createFragment('client.lastname') */
                  );
                },
              },
              {
                type: 'menuitem',
                text: 'Dirección',
                onAction: function () {
                  editor.insertContent(createFragment('client.address'));
                },
              },
              {
                type: 'menuitem',
                text: 'Email',
                onAction: function () {
                  editor.insertContent(createFragment('client.email'));
                },
              },
              {
                type: 'menuitem',
                text: 'Cédula de identidad',
                onAction: function () {
                  editor.insertContent(createFragment('client.rut'));
                },
              },
              {
                type: 'menuitem',
                text: 'Estado civil',
                onAction: function () {
                  editor.insertContent(createFragment('{civilStatus client.civilStatus}'));
                },
              },
              {
                type: 'menuitem',
                text: 'Nacionalidad',
                onAction: function () {
                  editor.insertContent(createFragment('{nationality client.nationality}'));
                },
              },
              {
                type: 'menuitem',
                text: 'Profesión',
                onAction: function () {
                  editor.insertContent(createFragment('client.profession'));
                },
              },
            ];
          },
        },
        {
          type: 'nestedmenuitem',
          text: 'Proyecto',
          icon: 'home',
          getSubmenuItems: function () {
            return [
              {
                type: 'menuitem',
                text: 'Nombre',
                onAction: function () {
                  editor.insertContent(createFragment('project.name'));
                },
              },
              {
                type: 'menuitem',
                text: 'Dirección',
                onAction: function () {
                  editor.insertContent(createFragment('project.address'));
                },
              },
              {
                type: 'menuitem',
                text: 'Constructora',
                onAction: function () {
                  editor.insertContent(createFragment('project.builderCompany'));
                },
              },
              {
                type: 'menuitem',
                text: 'Inmobiliaria',
                onAction: function () {
                  editor.insertContent(createFragment('project.company.name'));
                },
              },
            ];
          },
        },
        {
          type: 'nestedmenuitem',
          text: 'Unidad',
          icon: 'home',
          getSubmenuItems: function () {
            return [
              {
                type: 'menuitem',
                text: 'Número',
                onAction: function () {
                  editor.insertContent(createFragment('unit.name'));
                },
              },
              {
                type: 'menuitem',
                text: 'Piso',
                onAction: function () {
                  editor.insertContent(createFragment('unit.floor'));
                },
              },
              {
                type: 'menuitem',
                text: 'Modelo',
                onAction: function () {
                  editor.insertContent(createFragment('unit.model.name'));
                },
              },
            ];
          },
        },
      ];
      callback(items);
    },
  });
});

function createFragment(...text) {
  let result = "<span class='contract mceNonEditable'><b>";
  text.forEach(item => {
    result += '{{' + item + '}}';
  });
  result += '<b/></span>';
  return result;
}
